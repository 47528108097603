import { Styles } from "./types";

const styles: Styles = {
    section: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
        color: '#333'
    },
    title: {
        margin: '0 0 10px 0',
        color: '#282c34',
    },
    list: {
        listStyleType: 'none',
        padding: '0',
    },
    item: {
        marginBottom: '15px',
    },
    itemBody: {
        textAlign: 'left',
    }
};

export default function Services() {
    return (
        <section id="services" style={styles.section}>
            <h2 style={styles.title}>Our Services</h2>
            <ul style={styles.list}>
                <li style={styles.item}>
                    <h3>Cloud Architecture</h3>
                    <p style={styles.itemBody}>At Autom8 Cloud, we don't just design cloud architectures - we build robust, scalable solutions on AWS. Our approach is tailored to your specific needs, always adhering to AWS Well-Architected best practices.</p>
                </li>
                <li style={styles.item}>
                    <h3>Cloud Development</h3>
                    <p style={styles.itemBody}>We're a hands-on consultancy that goes beyond design. We implement serverless solutions and deploy infrastructure to the cloud using automated pipelines. With us, you're not just getting advice - you're getting action.</p>
                </li>
                <li style={styles.item}>
                    <h3>Cloud Migration</h3>
                    <p style={styles.itemBody}>Our team has firsthand experience migrating on-premise workloads to the cloud. We modernize codebases and design architectures with your future scaling needs in mind. With Autom8 Cloud, your cloud migration is in safe hands.</p>
                </li>
                <li style={styles.item}>
                    <h3>Cloud Security</h3>
                    <p style={styles.itemBody}>Security isn't an afterthought - it's at the forefront of every project we undertake. We implement best practices to ensure your cloud workloads are secure. With Autom8 Cloud, you can rest easy knowing your cloud infrastructure is secure.</p>
                </li>
            </ul>
        </section>
    );
}

