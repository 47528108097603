import { Styles } from "./types";

const styles: Styles = {
    section: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
        color: '#333'
    },
    title: {
        margin: '0 0 10px 0',
        color: '#282c34',
    },
    text: {
        lineHeight: '1.5',
        textAlign: 'left',
    },
};

export default function About() {
    return (
        <section id="about" style={styles.section}>
            <h2 style={styles.title}>About Us</h2>
            <p style={styles.text}>
                Autom8 Cloud is not just a cloud consultancy. We are your partners in navigating the complex world of cloud computing. 
                Our team of experts works closely with you to understand your unique needs and challenges, and then we design and implement
                cloud solutions that are tailored specifically for your business.
            </p>
            <p style={styles.text}>
                Whether you're just starting your cloud journey or looking 
                to optimize your existing cloud workloads, Autom8 Cloud is here to help.
            </p>
        </section>
    );
}