import { useState } from 'react';
import EmailValidator from 'email-validator';
import useFetch from './useFetch';
import React from 'react';

interface FormStateValue {
    value: string;
    error: string;
}

export interface ContactFormState {
    name: FormStateValue;
    email: FormStateValue;
    message: FormStateValue;
}

const ContactFormApi = {
    url: 'https://api.autom8.cloud/email',
    method: 'POST' as 'POST',
    additionalHeaders: {
        'x-api-key': atob('bXRUUzBUODl6STMyZVVwMklBVmcxMmc2VHJxaVIxNEQ1Z2xUMU9wdQ==')
    },
}

const DefaultFormStateValue: FormStateValue = { value: '', error: '' };
const DefaultFormState: ContactFormState = {
    name: {...DefaultFormStateValue},
    email: {...DefaultFormStateValue},
    message: {...DefaultFormStateValue},
}

function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

const useContactForm = () => {
    const { fetchData, isLoading, isError, isSuccess } = useFetch(ContactFormApi);
    const [formData, setFormData] = useState<ContactFormState>(DefaultFormState);
    
    const isValid = (formData: ContactFormState) => Object.values(formData).every(prop => prop.error === '');

    const validateProp = (propName: string, value: string) => {
        const propNameInMessage = capitalizeFirstLetter(propName);

        if (value === '') {
            return `${propNameInMessage} is required.`;
        }

        if (propName === 'email' && !EmailValidator.validate(value)) {
            return `${propNameInMessage} must be valid.`;
        }

        return '';
    };

    const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const prop = e.target.name as keyof ContactFormState;
        const value = e.target.value;
        const error = validateProp(prop, value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: {
                value,
                error: error,
            },
        }));
    }, []);

    const handleSubmit = React.useCallback(async (formData: ContactFormState) => {
        console.log({ formData });

        if (!isValid(formData)) {
            return false;
        }

        const requestBody = Object.entries(formData).reduce((result, [key, formValue]) => ({ ...result, [key]: formValue.value }), {});
        const result = await fetchData(requestBody);
        const successfullySend = result !== null && result.status === 'success';

        return successfullySend;
    }, [fetchData]);

    return {
        formData,
        isSubmitting: isLoading,
        isValid,
        isSuccessfullySubmitted: isSuccess,
        isUnsuccessfullySubmitted: isError,
        handleInputChange,
        handleSubmit,
    };
};

export default useContactForm;
