import { CSSProperties, useEffect } from 'react';
import ReactGa from 'react-ga';
import Header from './Header';
import About from './About';
import Services from './Services';
import ContactForm from './Contact';
import Footer from './Footer';
import OurClients from './OurClients';

const styles: { [key: string]: CSSProperties } = {
 global: {
    overflowX: 'hidden',
    backgroundColor: '#eeeeee',
    textAlign: 'center'
 },
 body: {
    margin: '0 auto',
    maxWidth: '1200px', // adjust as needed
    padding: '0 50px', // adjust as needed
 }
}

const GoogleAnalyticsMeasurementId = 'G-5VRH03T2B3';

function App() {
  useEffect(() => {
    ReactGa.initialize(GoogleAnalyticsMeasurementId);
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App" style={styles.global}>
      <Header />
      <div style={styles.body}>
        <About />
        <Services />
        <OurClients />
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
}

export default App;
