import styled from "styled-components";
import { Styles } from "./types";

const styles: Styles = {
    header: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#282c34',
        color: 'white'
    },
    title: {
        margin: '0',
    },
    link: {
        color: 'white',
        textDecoration: 'none',
    }
};

// Need to use styled-components to get media queries to work
const Nav = styled.nav`
    display: flex;
    gap: 15px;

    @media (max-width: 599px) {
        display: none;
    }
`

export default function Header() {
    return (
        <header style={styles.header}>
            <h1 style={styles.title}>Autom8 Cloud</h1>
            <Nav>
                <a href="#about" style={styles.link}>About</a>
                <a href="#services" style={styles.link}>Services</a>
                <a href="#contact" style={styles.link}>Contact</a>
            </Nav>
        </header>
    );
}

