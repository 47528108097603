import { Styles } from './types';

const styles: Styles = {
    footer: {
      padding: '20px',
      backgroundColor: '#282c34',
      color: 'white',
      textAlign: 'center',
      position: 'relative',
      bottom: '0',
      width: '100%',
    },
    text: {
      margin: '0',
    },
  };

export default function Footer() {
  return (
    <footer style={styles.footer}>
      <p style={styles.text}>© {new Date().getFullYear()} Autom8 Cloud. All rights reserved.</p>
    </footer>
  );
}

