import dentacloudLogo from './logos/dentacloud.png';
import aevumdefiLogo from './logos/aevumdefi.jpg';
import styled from 'styled-components';
import { Styles } from './types';

const styles: Styles = {
    section: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
        color: '#333'
    },
    title: {
        margin: '0 0 10px 0',
        color: '#282c34',
    },
    aevumDefiLogo: {
        width: '200px', // adjust as needed
        height: 'auto',
    },
};

const ClientsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }
`;

const Client = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 40%;
    @media (max-width: 800px) {
        width: 100%;
    }
`;

const DentacloudLogo = styled.img`
    padding: 80px;
    width: 175px;
    height: auto;
    background-color: #006098;
    @media (max-width: 800px) {
        gap: 0px;
        width: 150px;
        padding: 50px;
    }
`;

export default function OurClients() {
    return (
        <section id="clients" style={styles.section}>
            <h2 style={styles.title}>Our Clients</h2>
            <p>We are proud to have partnered with several innovators to accelerated their cloud adoption. Explore their stories below:</p>
            <ClientsContainer>
                <Client>
                    <h3>Dentacloud</h3>
                    <DentacloudLogo src={dentacloudLogo} alt="Dentacloud logo" style={styles.dentacloudLogo} />
                    <p>We partnered with Dentacloud to build their cloud infrastructure from the ground up. Our team set up their AWS account and CDK repository, implemented an AWS AppSync GraphQL API to serve their business logic, and leveraged GitHub actions to automate their build, test, and deploy pipelines. The result? A robust, efficient, and scalable cloud solution.</p>
                </Client>
                <Client>
                    <h3>Aevum Defi</h3>
                    <img src={aevumdefiLogo} alt="Aevum Defi logo" style={styles.aevumDefiLogo} />
                    <p>In our partnership with Aevum Defi, we're helping them to bring enterprise-software grade data collection and visualization into their Web3 ecosystem. We're creating a Data Lake that collects data from both Web2 and Web3 sources, and we're using AWS Quicksight to bring this data to life within their Dapps.</p>
                </Client>
            </ClientsContainer>
        </section>
    );
}