import React from 'react';
import ReactGa from 'react-ga';
import { useState } from 'react';

interface UseFetchProps {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;
    additionalHeaders?: Record<string, string>;
}

function useFetch<T>({ method, url, additionalHeaders }: UseFetchProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<unknown | null>(null);

    function resetState() {
        setIsLoading(true);
        setData(null);
        setError(null);
    }

    const fetchData = React.useCallback(async (body?: unknown) => {
        resetState();

        try {
            const response = await fetch(url, {
                method,
                ...( body ? { body: JSON.stringify(body) } : {}),
                headers: {
                    'Content-Type': 'application/json',
                    ...additionalHeaders,
                },
            });

            const responseData = await response.json();
            console.log({ responseData });

            setData(responseData);
            return responseData;
        } catch (error: unknown) {
            console.error(error);
            setError(error);

            const errorMessage = error instanceof Error ? error.message : error;
            ReactGa.exception({ description: errorMessage, fatal: true })

            return null;
        } finally {
            setIsLoading(false);
        }
    }, [method, url, additionalHeaders]);

    return { 
        isLoading,
        isSuccess: !isLoading && !!data && !error, 
        isError: !isLoading && !!error,
        data, 
        error, 
        fetchData 
    };
};

export default useFetch;
