import React, { useState } from 'react';
import { Styles } from './types';
import useContactForm, { ContactFormState } from './hooks/useContactForm';

const styles: Styles = {
    section: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
        color: '#333'
    },
    title: {
        margin: '0 0 10px 0',
        color: '#282c34',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    input: {
        padding: '10px',
        border: '1px solid #ccc',
    },
    textarea: {
        padding: '10px',
        border: '1px solid #ccc',
        minHeight: '100px',
    },
    button: {
        padding: '10px',
        backgroundColor: '#282c34',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
    },
    success: {
        color: 'green',
    },
    text: {
        marginBottom: '20px',
    }
};

enum ContactFormStatus {
    PENDING = 'PENDING',
    SUBMITTED = 'SUBMITTED',
    FAILED = 'FAILED',
}

function SubmittedMessage() {
    return (
        <div>
            <h3 style={styles.success}>Your email has been successfully submitted.</h3>
            <p>Thanks for visiting Autom8 Cloud. We'll be in touch soon!</p>
            <p>P.S. This website is entirely AWS Serverless: Cloudfront + S3 + API Gateway + SNS 😀</p>
        </div>
    );
}

function FailedMessage() {
    return (
        <div>
            <h3 style={styles.error}>There was a problem submitting your email.</h3>
            <p>I'm sorry for the inconvenience. Please try to reach out again later.</p>
        </div>
    );
}

function ContactForm({ onSend }: { onSend: (isSuccessful: boolean) => void }) {
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const { formData, handleSubmit, handleInputChange, isValid, isSubmitting } = useContactForm();

    const onSubmit = async (event: React.FormEvent, data: ContactFormState) => {
        event.preventDefault();

        const isSubmitted = await handleSubmit(data);
        if (!isSubmitted && !isValid(data)) {
            setShowValidationErrors(true);
            return;
        }

        onSend(isSubmitted);
    };

    const getValidationErrorIfExists = (error: string) => {
        if (!showValidationErrors) {
            return null;
        }

        if (error === '') {
            return null;
        }

        return <span style={styles.error}>{error}</span>;
    };

    const renderButton = () => {
        if (isSubmitting) {
            return <button type="button" disabled style={styles.button}>Sending...</button>;
        }

        return <button type="submit" style={styles.button}>Send</button>;
    }

    return (
        <form onSubmit={(event: React.FormEvent) => onSubmit(event, formData)} style={styles.form}>
            <label style={styles.label}>
                Name
                {getValidationErrorIfExists(formData.name.error)}
                <input type="text" name="name" onChange={handleInputChange} style={styles.input} value={formData.name.value} />
            </label>
            <label style={styles.label}>
                Email
                {getValidationErrorIfExists(formData.email.error)}
                <input type="email" name="email" onChange={handleInputChange} style={styles.input} value={formData.email.value} />
            </label>
            <label style={styles.label}>
                Message
                {getValidationErrorIfExists(formData.message.error)}
                <textarea name="message" onChange={handleInputChange} style={styles.textarea} value={formData.message.value} />
            </label>
            {renderButton()}
        </form>
    );
}

function Contact() {
    const [contactFormStatus, setContactFormStatus] = useState<ContactFormStatus>(ContactFormStatus.PENDING);

    const handleSend = (isSuccessful: boolean) => {
        if (!isSuccessful) {
            setContactFormStatus(ContactFormStatus.FAILED);
            return;
        }

        setContactFormStatus(ContactFormStatus.SUBMITTED);
    }

    const renderContactForm = () => {
        switch (contactFormStatus) {
            case ContactFormStatus.SUBMITTED:
                return <SubmittedMessage />;
            case ContactFormStatus.FAILED:
                return <FailedMessage />;
            case ContactFormStatus.PENDING:
            default:
                return <ContactForm onSend={handleSend} />;
        }
    }

    return (
        <section id="contact" style={styles.section}>
            <h2 style={styles.title}>Contact Us</h2>
            <p style={styles.text}>
                If you're ready to take your cloud infrastructure to the next level with AWS, we're here to help.
                Reach out to us today and let's discuss how we can help you achieve your cloud goals.
            </p>
            {renderContactForm()}
        </section>
    );
}

export default Contact;